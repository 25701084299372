import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';
import { ContentBlock } from '../../../common/ContentBlock';
import * as styles from './ScreenTwo.module.scss';

const ScreenTwo = ({ text, image }) => {
  return (
    <>
      <ContentBlock className={styles.introWrapper}>
        <h2 className="type-large">{text}</h2>
      </ContentBlock>
      <ContentBlock className={styles.introWrapper}>
        <div className={styles.imageWrapper}>
          {image?.image && (
            <GatsbyImage
              image={image?.image?.asset?.gatsbyImageData}
              alt={image?.altText}
              loading="eager"
            />
          )}
        </div>
      </ContentBlock>
    </>
  );
};

export { ScreenTwo };
