// const postUrl = `https://genhybridsystems.us1.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;

// action = https://alistairmcclymont.us2.list-manage.com/subscribe/post
// u = f744eb30f4b123f72a680520d
// id = bb3f6d7fddid

import React, { useContext } from 'react';
import * as styles from './MailchimpForm.module.scss';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { IconButton } from '../IconButton';
import { Context } from '../../../context/Context';
import { localizeField } from '../../../utils/locale';
// a basic form
const CustomForm = ({ status, message, onValidated, mailchimpLocaleText }) => {
  let fullName, email, printMessage;

  // if message has a code remove it
  if (message) {
    printMessage = message.startsWith('0 - ') ? message.substring(3) : message;
  }

  const submit = (e) => {
    e.preventDefault();

    email &&
      fullName &&
      // email.value.indexOf('@') > -1 &&
      email.value !== 'null' &&
      onValidated({
        MERGE0: email.value,
        MERGE1: fullName.value,
      });
  };

  const { language } = useContext(Context);

  return (
    <div className={styles.mailchimpForm}>
      <form>
        <div className={styles.formFields}>
          <div className={styles.name}>
            <label htmlFor="fullname" className="visually-hidden">
              {mailchimpLocaleText.mailchimpFullnameLabel}
            </label>
            <input
              ref={(node) => (fullName = node)}
              type="text"
              name="fullname"
              id="fullname"
              placeholder={mailchimpLocaleText.mailchimpFullnameLabel}
            />
          </div>
          <div className={`${styles.email}`}>
            <label htmlFor="email" className="visually-hidden">
              {mailchimpLocaleText.mailchimpEmailLabel}
            </label>
            <input
              ref={(node) => (email = node)}
              type="email"
              name="email"
              id="email"
              placeholder={mailchimpLocaleText.mailchimpEmailLabel}
            />
          </div>
          <div className={styles.submitWrapper}>
            {/* <label htmlFor="button" className="hidden">
              {mailchimpLocaleText.mailchimpSubscribeButtonText}
            </label> */}
            <IconButton
              id="button"
              icon="arrow-right"
              iconProps={{ size: 'medium' }}
              onClick={submit}
              bg="grey"
              className={styles.submit}
              hiddenText={mailchimpLocaleText.mailchimpSubscribeButtonText}>
              {/* {localizeField(
                mailchimpLocaleText.mailchimpSubscribeButtonText,
                language.locale
              )} */}
            </IconButton>
          </div>
        </div>
      </form>
      {status === 'sending' && (
        <div>{mailchimpLocaleText.mailchimpSendingStatus}</div>
      )}
      {status === 'error' && (
        <div
          className={styles.errorMessage}
          // substring below to trim the number off the error
          dangerouslySetInnerHTML={{ __html: printMessage }}
        />
      )}
      {status === 'success' && (
        <div
          className={styles.success}
          dangerouslySetInnerHTML={{ __html: printMessage }}
        />
      )}
      {/* <p>
        Emails will be very infrequent, at most a handful per year. This email
        list is to let you know about new exhibitions, or other relevant events
        that might be going on.
      </p> */}
      {/* <p className="hidden">
        {mailchimpLocaleText.mailchimpUnsubscribeMessage}
      </p> */}
    </div>
  );
};

const MailchimpForm = ({ mailchimpLocaleText }) => {
  const url =
    'https://the14thfactory.us14.list-manage.com/subscribe/post?u=b510d59964873890e2e991f80&id=5cb0634c58';

  return (
    <div className={styles.mailchimpWrapper}>
      <h2 className="type-large">
        {mailchimpLocaleText.mailchimpSubscribeTitle}
      </h2>
      <h3 className="type-medium">
        {mailchimpLocaleText.mailchimpSubscribeMessage}
      </h3>
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
            mailchimpLocaleText={mailchimpLocaleText}
          />
        )}
      />
    </div>
  );
};

export { MailchimpForm };
