import React from "react";
import * as styles from "./ScreenHeightBlock.module.scss";
import classNames from "classnames";

const ScreenHeightBlock = React.forwardRef(
  ({ children, fixed, padded, minusHeader, className, extraPadded }, ref) => {
    const cls = classNames(
      { [styles.fixed]: fixed },
      { [styles.padded]: padded },
      { [styles.extraPadded]: extraPadded },
      { [styles.minusHeader]: minusHeader },
      styles.screenHeightBlock,
      className
    );
    return (
      <div ref={ref} className={cls}>
        {children}
      </div>
    );
  }
);

export { ScreenHeightBlock };
