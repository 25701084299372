import React from 'react';
import { graphql } from 'gatsby';
import localize from '../components/localize';
import Seo from '../components/seo';

import { HomePageJune22 } from '../components/pageLayout/HomePage/HomePageJune22';

export const query = graphql`
  query HomePageQuery {
    home: sanitySingletonHomepage {
      title
      vimeo
      vimeoHeader {
        _type
        en
        sc
        tc
      }
      vimeoSubHeader {
        _type
        en
        sc
        tc
      }
      whatIsIntro {
        _type
        en
        sc
        tc
      }
      whatIsTitle {
        _type
        en
        sc
        tc
      }
      _rawWhatIsBody(resolveReferences: { maxDepth: 10 })

      locationImage {
        altText
        image {
          asset {
            gatsbyImageData(layout: FULL_WIDTH, width: 1920)
          }
        }
      }

      whatsOnHeader {
        _type
        en
        sc
        tc
      }
      whatsOnMainEvent {
        eventDisplayName {
          _type
          en
          sc
          tc
        }
        shortIntro {
          _type
          en
          sc
          tc
        }
        slug {
          current
        }
        featuredImage {
          altText
          image {
            asset {
              gatsbyImageData(aspectRatio: 1.9, layout: FULL_WIDTH, width: 1920)
            }
          }
        }
      }
      events {
        eventDisplayName {
          _type
          en
          sc
          tc
        }
        shortIntro {
          _type
          en
          sc
          tc
        }
        slug {
          current
        }
        featuredImage {
          altText
          image {
            asset {
              gatsbyImageData(aspectRatio: 1.27, layout: FULL_WIDTH, width: 960)
            }
          }
        }
      }
      nftHeader {
        _type
        en
        sc
        tc
      }
      nftStrapline {
        _type
        en
        sc
        tc
      }
      nftFeatures {
        nftDisplayName {
          _type
          en
          sc
          tc
        }
        artist {
          artistDisplayName {
            _type
            en
            sc
            tc
          }
        }
        price {
          _type
          en
          sc
          tc
        }
        currency
        shortIntro {
          _type
          en
          sc
          tc
        }
        slug {
          current
        }
        featuredImage {
          altText
          image {
            asset {
              gatsbyImageData(aspectRatio: 1.27, width: 960, layout: FULL_WIDTH)
            }
          }
        }
      }
    }

    homeJune: sanitySingletonHomeJune22 {
      screenOneText {
        _type
        en
        sc
        tc
      }
      screenTwoText {
        _type
        en
        sc
        tc
      }
      screenThreeImage {
        altText
        image {
          asset {
            gatsbyImageData(width: 1920)
          }
        }
      }
      screenFiveText {
        _type
        en
        sc
        tc
      }
      _rawScreenSixBody(resolveReferences: { maxDepth: 10 })
      screenSixBody {
        _rawEn(resolveReferences: { maxDepth: 10 })
        _rawSc(resolveReferences: { maxDepth: 10 })
        _rawTc(resolveReferences: { maxDepth: 10 })
        _type
      }
    }

    common: sanitySingletonCommon {
      _rawDatesBody(resolveReferences: { maxDepth: 10 })
      _rawAddressBody(resolveReferences: { maxDepth: 10 })
    }

    signup: sanitySingletonSignUp {
      pageHeader {
        _type
        en
        sc
        tc
      }
      _rawIntroBody(resolveReferences: { maxDepth: 10 })
    }

    mailchimpLocaleText: sanitySingletonCommon {
      mailchimpSendingStatus {
        _type
        en
        sc
        tc
      }
      mailchimpFullnameLabel {
        _type
        en
        sc
        tc
      }
      mailchimpEmailLabel {
        _type
        en
        sc
        tc
      }
      mailchimpFirstnameLabel {
        _type
        en
        sc
        tc
      }
      mailchimpLastnameLabel {
        _type
        en
        sc
        tc
      }
      mailchimpSubscribeButtonText {
        _type
        en
        sc
        tc
      }
      mailchimpUnsubscribeMessage {
        _type
        en
        sc
        tc
      }
      mailchimpSubscribeTitle {
        _type
        en
        sc
        tc
      }
      mailchimpSubscribeMessage {
        _type
        en
        sc
        tc
      }
    }
  }
`;

const IndexPage = ({ data, location }) => {
  return (
    <main>
      {/* <title>Fourteenth Factory Home</title> */}
      <Seo title="Fourteenth Factory Home" />
      {/* <h1>Fourteenth Factory Home</h1> */}
      <HomePageJune22
        content={data?.home}
        common={data?.common}
        placeholderContent={data.homeJune}
        mailchimpLocaleText={data?.mailchimpLocaleText}
        signup={data?.signup}
        hash={location?.hash}
      />
    </main>
  );
};

export default localize(IndexPage);
