// extracted by mini-css-extract-plugin
export var typeLarge = "HomePageJune22-module--type-large--qhgwD";
export var typeMedium = "HomePageJune22-module--type-medium--r4QEI";
export var typeBody = "HomePageJune22-module--type-body--jlHOQ";
export var homeIcon = "HomePageJune22-module--home-icon--W6T8P";
export var blueBackground = "HomePageJune22-module--blue-background--gP+PX";
export var yellowBackground = "HomePageJune22-module--yellow-background--wzCFC";
export var whiteBackground = "HomePageJune22-module--white-background--7OA1M";
export var scroll = "HomePageJune22-module--scroll--RUyby";
export var signupContainer = "HomePageJune22-module--signup-container---EP2O";
export var heroesWrapper = "HomePageJune22-module--heroes-wrapper--C8htC";
export var logo = "HomePageJune22-module--logo--gGWyo";
export var leftText = "HomePageJune22-module--left-text--Srw9V";
export var rightText = "HomePageJune22-module--right-text--bmPZZ";