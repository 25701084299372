import React from 'react';
import { Link } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';
import { ContentBlock } from '../../common/ContentBlock';
import { MailchimpForm } from '../../common/MailchimpForm';

const SignUpSection = ({ signup, mailchimpLocaleText }) => {
  return (
    <>
      <ContentBlock>
        <MailchimpForm mailchimpLocaleText={mailchimpLocaleText} />
      </ContentBlock>
    </>
  );
};

export { SignUpSection };
