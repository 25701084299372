// extracted by mini-css-extract-plugin
export var typeLarge = "MailchimpForm-module--type-large--I9XR0";
export var typeMedium = "MailchimpForm-module--type-medium--tDLHf";
export var typeBody = "MailchimpForm-module--type-body--qoZLP";
export var homeIcon = "MailchimpForm-module--home-icon--HVr0O";
export var mailchimpWrapper = "MailchimpForm-module--mailchimp-wrapper--Qjvod";
export var mailchimpForm = "MailchimpForm-module--mailchimp-form--Rc1EQ";
export var errorMessage = "MailchimpForm-module--error-message--2lT6A";
export var success = "MailchimpForm-module--success--0pPeD";
export var optional = "MailchimpForm-module--optional--Pe6qR";
export var submitWrapper = "MailchimpForm-module--submit-wrapper--YsUPn";
export var submit = "MailchimpForm-module--submit--FZw-E";
export var formFields = "MailchimpForm-module--form-fields--3dpqe";
export var name = "MailchimpForm-module--name--0NNJG";
export var email = "MailchimpForm-module--email--N+dxe";