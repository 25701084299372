import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';
import { ContentBlock } from '../../../common/ContentBlock';
import * as styles from './ScreenSix.module.scss';

const ScreenSix = ({ body, header }) => {
  return (
    <>
      <ContentBlock className={styles.headlineWrapper}>
        <h2 className="type-large">{header}</h2>
      </ContentBlock>
      <ContentBlock className={styles.bodyWrapper}>
        <div className="type-medium">
          <BlockContent blocks={body} />
        </div>
      </ContentBlock>
    </>
  );
};

export { ScreenSix };
