import React from 'react';
// import ReactPlayer from "react-player/lazy";
import * as styles from './BackgroundVideo.module.scss';

//https://player.vimeo.com/video/682759865?h=b54d7c57c2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479
const BackgroundVideo = ({ vimeo, children }) => {
  return (
    <div className={styles.vimeoWrapper}>
      <div className={styles.overlayWrapper}>
        <div className={styles.overlay}>{children}</div>
      </div>
      <iframe
        src={`${vimeo}&background=1&autoplay=1&muted=1&loop=1`}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        loading="lazy"
        title="background artwork video"></iframe>
      <div className={styles.background} />
    </div>
  );
};

export { BackgroundVideo };
