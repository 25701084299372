import React, { useRef, useMemo, createRef, useEffect } from 'react';
import { Link } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';
import { ScreenHeightBlock } from '../../../common/ScreenHeightBlock';
import { ContentBlock } from '../../../common/ContentBlock';
import { FLogoStatic } from '../../../common/FLogoStatic';
import { BackgroundVideo } from '../../../common/BackgroundVideo';
import { SignUpSection } from '../../SignUpSection';
import { WhatIsSection } from '../WhatIsSection';
import { LocationSection } from '../LocationSection';
import { WhatsOnSection } from '../WhatsOnSection';
import { NftSection } from '../NftSection';
import { ScreenTwo } from '../ScreenTwo';
import { ScreenFour } from '../ScreenFour';
import { ScreenSix } from '../ScreenSix';
import { IconButton } from '../../../common/IconButton';
import { smoothScrollTo } from '../../../../utils/common';

import * as styles from './HomePageJune22.module.scss';

const HomePageJune22 = ({
  content,
  common,
  placeholderContent,
  mailchimpLocaleText,
  signup,
  hash,
}) => {
  const scrollTwoRef = useRef(null);

  console.log('placeholderContent ', placeholderContent);

  const handleClick = (e) => {
    // access to e.target here
    smoothScrollTo(scrollTwoRef?.current, 0);
  };

  const signupRef = useMemo(() => {
    return createRef();
  });

  useEffect(() => {
    if (
      hash &&
      signupRef?.current &&
      hash.substring(1).trim() // Changed this to satisfy linter, hopefully still works
    ) {
      return smoothScrollTo(signupRef?.current);
    }
  }, [hash]);

  return (
    <>
      {/* Top Hero Video */}
      <ScreenHeightBlock minusHeader fixed>
        <BackgroundVideo
          vimeo={
            'https://player.vimeo.com/video/682759865?h=b54d7c57c2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
          }
          ratio={42.19}>
          {/* <h1>{content?.vimeoHeader}</h1> */}
          <div padded className={styles.heroesWrapper}>
            <div className={styles.leftText}>
              <h3>The 14th</h3>
            </div>
            <FLogoStatic className={styles.logo} />
            <div className={styles.rightText}>
              <h3>Factory</h3>
            </div>
          </div>
          <IconButton
            icon="chevron"
            iconProps={{ size: 'large' }}
            onClick={handleClick}
            bg="transparent"
            className={styles.scroll}
            hiddenText="scroll down"
          />
          <h2>{placeholderContent.screenOneText}</h2>
        </BackgroundVideo>
      </ScreenHeightBlock>

      {/* Screen Two */}
      <div ref={scrollTwoRef}>
        <ScreenHeightBlock extraPadded>
          <ScreenTwo
            text={placeholderContent?.screenTwoText}
            image={placeholderContent?.screenThreeImage}
          />
        </ScreenHeightBlock>
      </div>

      {/* Screen Four */}
      {/* <ScreenHeightBlock extraPadded>
        <ScreenFour />
      </ScreenHeightBlock> */}

      {/* Screen Six */}
      <ScreenHeightBlock extraPadded className={styles.blueBackground}>
        <ScreenSix
          header={placeholderContent.screenFiveText}
          body={placeholderContent._rawScreenSixBody}
        />
      </ScreenHeightBlock>

      {/* Location and Dates */}
      {/* <ContentBlock className={styles.location}>
        <LocationSection
          dates={common?._rawDatesBody}
          address={common?._rawAddressBody}
          image={content?.locationImage}
        />
      </ContentBlock> */}

      {/* What's On Features */}
      {/* <ContentBlock extraPadded className={styles.whatsOn}>
        <WhatsOnSection
          header={content?.whatsOnHeader}
          mainEvent={content?.whatsOnMainEvent}
          events={content?.events}
          color={'green'}
        />
      </ContentBlock> */}

      {/* NFT Features */}
      {/* <ContentBlock extraPadded className={styles.whatsOn}>
        <NftSection
          header={content?.nftHeader}
          strapline={content?.nftStrapline}
          nfts={content?.nftFeatures}
          color={'purple'}
        />
      </ContentBlock> */}

      {/* Sign Up */}
      <ScreenHeightBlock className={styles.signupContainer}>
        <ContentBlock extraPadded>
          <SignUpSection
            signup={signup}
            mailchimpLocaleText={mailchimpLocaleText}
          />
        </ContentBlock>
      </ScreenHeightBlock>
    </>
  );
};

export { HomePageJune22 };
